import { useTranslation } from 'react-i18next'
import { supportedLngs } from '~/configs/i18n'

export type language = {
	lngShort: lngShort
	lngLong: lngLong
}
export type lngLong = 'sv-SE' | 'en-GB' | 'no-NO' | 'da-DK' | 'fi-FI'
export type lngShort = 'sv' | 'en' | 'no' | 'da' | 'fi'
export type countryList = 'SE' | 'DK' | 'NO' | 'FI'

export enum languageList {
	Swedish = 'sv',
	English = 'en',
	Norwegian = 'no',
	Danish = 'da',
	Finnish = 'fi',
}

export enum currencyList {
	SEK = 'SEK',
	EUR = 'EUR',
	NOK = 'NOK',
	DKK = 'DKK',
	GBP = 'GBP',
	USD = 'USD',
}

export const seoLanguageMap: Record<string, string> = {
	se: 'sv',
	en: 'en',
	dk: 'da',
	no: 'no',
	fi: 'fi',
}

export function getServerLang(request: Request): language {
	const url = new URL(request.url)
	const firstPart = url.pathname.split('/')[1]

	// Check if the first part is a valid two-letter language code
	if (firstPart && firstPart.length === 2) {
		const lngShort = firstPart.toLowerCase() as lngShort
		if (supportedLngs.includes(lngShort)) {
			return {
				lngLong: getLong(lngShort),
				lngShort: lngShort,
			}
		}
		return {
			lngLong: getLong('sv'),
			lngShort: 'sv',
		}
	} else {
		return {
			lngLong: getLong('sv'),
			lngShort: 'sv',
		}
	}
}

export const getLong = (lng: string): lngLong => {
	if (lng === 'no') {
		return 'no-NO'
	}
	if (lng === 'da') {
		return 'da-DK'
	}
	if (lng === 'en') {
		return 'en-GB'
	}
	if (lng === 'fi') {
		return 'fi-FI'
	}
	return 'sv-SE'
}

export const useClientLang = (): {
	lngShort: lngShort
	lngLong: lngLong
} => {
	const { i18n } = useTranslation()
	const lngShort = i18n.language as lngShort

	return { lngShort, lngLong: getLong(lngShort) }
}

export const acceptedCountries = [
	{
		code: 'SE',
		long: 'Sweden',
		language: 'sv',
	},
	{
		code: 'DK',
		long: 'Denmark',
		language: 'da',
	},
	{
		code: 'NO',
		long: 'Norway',
		language: 'no',
	},
	{
		code: 'FI',
		long: 'Finland',
		language: 'fi',
	},
] as {
	code: countryList
	language: lngShort
	long: 'Sweden' | 'Denmark' | 'Norway' | 'Finland'
}[]

export function getLanguageFromCountryCode(code?: string): lngShort {
	if (!code) return 'sv'
	const country = acceptedCountries.find(
		x => x.code.toLowerCase() === code.toLowerCase(),
	)
	return country ? country.language : 'sv'
}

// TODO: Translate in a better way
export function getCountryNameByLanguage(code: string, lng: lngShort) {
	if (lng === 'sv') {
		if (code === 'SE') return 'Sverige'
		if (code === 'DK') return 'Danmark'
		if (code === 'NO') return 'Norge'
		if (code === 'FI') return 'Finland'
	} else if (lng === 'da') {
		if (code === 'SE') return 'Sverige'
		if (code === 'DK') return 'Danmark'
		if (code === 'NO') return 'Norge'
		if (code === 'FI') return 'Finland'
	} else if (lng === 'no') {
		if (code === 'SE') return 'Sverige'
		if (code === 'DK') return 'Danmark'
		if (code === 'NO') return 'Norge'
		if (code === 'FI') return 'Finland'
	} else if (lng === 'fi') {
		if (code === 'SE') return 'Ruotsi'
		if (code === 'DK') return 'Tanska'
		if (code === 'NO') return 'Norja'
		if (code === 'FI') return 'Suomi'
	} else {
		if (code === 'SE') return 'Sweden'
		if (code === 'DK') return 'Denmark'
		if (code === 'NO') return 'Norway'
		if (code === 'FI') return 'Finland'
	}
}

export function getCountryCodeByLanguage(lng: lngShort) {
	if (lng === 'sv') return 'SE'
	if (lng === 'da') return 'DK'
	if (lng === 'no') return 'NO'
	if (lng === 'fi') return 'FI'
}
